<template>
   <section class="cont operate-box">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>预售券</el-breadcrumb-item>
            <el-breadcrumb-item>分销海报列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="oper-content-box">
         <!-- tag-box -->
         <el-row class="tag-box">
            <el-row class="tab-flex">
               <el-row :class="status === 'PROCESSING'? 'active': ''" @click.native="changeState('PROCESSING')">
                  <p class="p1">进行中</p>
                  <p class="p2"><span>{{ statisticsData.processingCount }}</span>个</p>
               </el-row>
               <el-row :class="status === 'NOT_STARTED'? 'active': ''" @click.native="changeState('NOT_STARTED')">
                  <p class="p1">未开始</p>
                  <p class="p2"><span>{{ statisticsData.notStartedCount }}</span>个</p>
               </el-row>
               <el-row :class="status === 'OVER' ? 'active': ''" @click.native="changeState('OVER')">
                  <p class="p1">已结束</p>
                  <p class="p2"><span>{{ statisticsData.overCount }}</span>个</p>
               </el-row>
            </el-row>
         </el-row>
         <el-row class="list">
            <el-row class="card-wrapper" v-for="(item,index) in tableData" :key="index">
               <el-row class="header">
                  <el-row class="card-title">{{ item.mainTitle }}</el-row>
               </el-row>
               <el-row class="distr-content">
                  <el-row class="left-img">
                     <img width="200" :src="item.image" alt="">
                  </el-row>
                  <el-row>
                     <el-button class="bg-gradient" type="primary" @click="handleDistribution(item)">添加渠道商</el-button>
                     <el-row class="right-form" v-for="(row, index1) in item.distributorCfgDTOS" :key="index1">
                        <el-row class="form-item">
                           <el-row class="m-right-30">
                              <el-row><span class="name">渠道商：</span>{{ row.name }}</el-row>
                              <span class="name">专属链接</span>
                              <el-input :id="'link' + row.distributorId" v-model="row.linkWechat" class="width-280 m-right-10"></el-input>
                              <el-button @click="handleCopy(row.distributorId)" class="bg-gradient" type="primary">复制</el-button>
                           </el-row>
                           <img class="width-90 m-right-10" :src="row.wechatCode" alt="" @click="lookimginfo(row,'one')">
                           <img class="width-90 m-right-10" :src="row.appletsCode" alt="" @click="lookimginfo(row,'two')">
                           <el-button class="bg-gradient" type="primary" @click="handleDownload(row)">下载</el-button>
                        </el-row>
                     </el-row>
                  </el-row>
               </el-row>
            </el-row>
         </el-row>
        <!-- 商品大图查看框 -->
        <el-dialog style="" align="center" :visible.sync="permissionVisible" width="30%" >
          <img :src="imginfo" />
        </el-dialog>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
      </el-row>
      <!-- 分配渠道商 -->
      <el-dialog
            title="渠道商"
            :visible.sync="visibleDis"
            width="30%">
         <el-row>
            <el-col class="table-box" :span="11">
               <div class="top-text"><span>未选渠道</span></div>
               <el-table
                     :data="unselectedData"
                     border
                     height="252"
                     @selection-change="handleSelectionChange"
                     :stripe="true"
               >
                  <el-table-column type="selection"></el-table-column>
                  <el-table-column prop="name" label="渠道名称"></el-table-column>
               </el-table>
            </el-col>
            <el-col class="table-box-arrow" style="text-align: center" :span="2">
               <el-button type="text" @click.native="saveDistribution"  :disabled="buttonbool"><i class="el-icon-d-arrow-right"></i></el-button>
            </el-col>
            <el-col class="table-box" :span="11">
               <div class="top-text"><span>已选渠道</span></div>
               <el-table
                     :data="selectedData"
                     border
                     height="252"
                     :stripe="true">
                  <el-table-column prop="name" label="渠道名称"></el-table-column>
                  <el-table-column :label="$t('msg.remove')" fixed="right">
                     <template slot-scope="scope">
                        <el-button @click="handleRemoves(scope.row)" type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                           <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
            </el-col>
         </el-row>
         <el-row slot="footer">
            <el-button class="bg-gradient" @click="refreshPosters">确定</el-button>
         </el-row>
      </el-dialog>
   </section>
</template>

<script>
import { urlObj } from "@/api/interface"
import { preSale , distributor } from "@/api/interface/business";
import { mapState } from 'vuex'
   // 引入子组件(分页)
   export default {
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.hotelCode = this.hotelInfo.hotelCode
                  this.getPosters()
                  this.getStatisticsCouponPresell()
               }
            }
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      data(){
         return{
            buttonbool:false,
            imginfo:'',
            permissionVisible:false,
            state: 'IN_PROGRESS',   // 活动状态
            hotelId: '',            // 酒店ID
            tableData: [],          // 表格
            statisticsData: 0,      // 统计总数
            inProgressCount: 0,     // 进行中
            rightNowCount: 0,       // 即将开始
            endCount: 0,            // 已结束
            draftCount: 0,          // 草稿
            total: 0,               // 表格总数
            page: 1,                // 当前页
            limit: 0,               // 每页数
            status: 'PROCESSING',   // 状态
            visibleDis: false,      // 分配弹窗
            unselectedData: [],     // 未选渠道数据
            selectedData: [],       // 已选渠道列表
            hotelCode: '',          // 酒店编码
            // 提示文本
            add_success: "添加成功！",
            del_success: "删除成功！",
            update_success: "修改成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！',
            release_success: '发布成功！',
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelCode = this.hotelInfo.hotelCode
         this.hotelId = this.hotelInfo.id
         this.getPosters()
         this.getStatisticsCouponPresell()
      },
      methods:{
         //图片遮罩层
        lookimginfo(e,item){
          console.log(e)
          if(item == 'one'){
            this.permissionVisible = true
            this.imginfo = e.wechatCode
          }else{
            this.permissionVisible = true
            this.imginfo = e.appletsCode
          }

        },
     // 刷新海报列表
         refreshPosters(){
           this.visibleDis = false
           this.getPosters()
         },
         // 改变状态
         changeState(val){
            this.status = val
            this.getPosters()
         },
         // 海报列表
         getPosters(){
            const url = preSale.posterPage
            const param = {
               limit: this.limit,
               page: this.page,
               status: this.status,
               hotelId: this.hotelId,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.total = res.total
                  let tableData = res.records
                  let obj = {}
                  tableData.forEach(item=>{
                     item.ewm = 'data:image/png;base64,' + item.qrCode
                     obj = JSON.parse(item.coverPath)
                     item.image = window.getResourcesHost() + obj.location + '/' + obj.formatName
                  })
                  this.tableData = tableData
               }
            })
         },
         // 改变每页数
         changePageNum(num){
            this.limit = num
            this.getPosters()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getPosters()
         },
         // 获取分销统计数
         getStatisticsCouponPresell(){
           const url = preSale.statisticsCouponPresell
           const param = { hotelId: this.hotelId }
           this.$axios.post(url, param).then(res => {
              if (res.success) {
                 this.statisticsData = res.records
              }
           })
         },
         // 处理复制
         handleCopy(id) {
            document.getElementById('link' + id).select()
            document.execCommand('copy')
            this.$message({ message: '已复制', type: 'success' })
         },
         // 处理下载
         handleDownload(row){
            const url = preSale.downloadBatch
            const param = {
               wechatCode: row.wechatCode,
               appletsCode: row.appletsCode,
               coverWechat: row.coverWechat,
               coverApplets: row.coverApplets,
               name: row.name
            }
            this.$axios.get(url, param, {}, 'blob').then(res => {
              const content = res
              const blob = new Blob([content], {type: "application/zip"})
              // console.log(window.URL.createObjectURL(blob))
              var timestamp = (new Date()).valueOf();
              const fileName = timestamp + '.zip'
              if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = window.URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                console.log(elink)
                window.URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
              } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
              }
            })
         },
         // 处理分配
         handleDistribution(row){
            this.visibleDis = true
            let coverPathObj = JSON.parse(row.coverPath)
            this.coverPath = window.getResourcesHost().replace('http', 'https') + coverPathObj.location+'/' + coverPathObj.formatName
            this.couponId = row.id
            this.categoryName = this.hotelCode + '/' + coverPathObj.location
            this.getSelectedChannel()
         },
         // 获取已选渠道列表
         getSelectedChannel(){
            const url = distributor.getBindList
            const param = { hotelId: this.hotelId, couponId: this.couponId }
            this.$axios.post(url, param).then(res => {
               this.selectedData = res.records
               this.getUnselectedChannel()
            })
         },
         // 获取未选渠道列表
         getUnselectedChannel(){
            const url = distributor.distributor
            const param = { hotelId: this.hotelId }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let unselectedData = res.records
                  for (let i = unselectedData.length - 1; i >= 0; i --){
                     for (let j = 0;j <= this.selectedData.length - 1;j ++){
                        if (unselectedData[i]){
                           if (unselectedData[i].id === this.selectedData[j].distributorId){
                              unselectedData.splice(i, 1)
                           }
                        }
                     }
                  }
                  this.unselectedData = unselectedData
                  this.buttonbool = false

               }
            })
         },
         // 未选渠道 获取未选渠道列表选中元素
         handleSelectionChange(val){
            this.distributorIds = val.map(item => {
               return item.id
            })
         },
         // 确认分配
         saveDistribution(){
            this.buttonbool = true
            const url = distributor.bindChannel
            const param = {
               distributorIds: this.distributorIds.join(),
               hotelId: this.hotelId,
               couponId: this.couponId,
               coverPath: this.coverPath,
               categoryName: this.categoryName
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     message: '分配成功!',
                     type: 'success'
                  });
                  this.getSelectedChannel()
               }
            })
         },
         // 删除已分配渠道
         handleRemoves(row){
            const url = distributor.deleteChannel
            let param = { categoryName: row.categoryName, id: row.id }
            this.$axios.post(url,param).then(res => {
               if (res.success) {
                  this.$message({
                     message: '渠道删除成功!',
                     type: 'success'
                  })
                  this.getSelectedChannel()
               }
            })
         },
      },
   }
</script>

<style scoped lang="scss">
//.main{
//   padding: 10px 30px;
   .top{
      padding: 20px 0;
      .top-input { float: right }
   }
   .tab{
      background: #ffffff;
      .tab-flex {
         display: flex; padding-left: 40px; font-size: 14px;
         cursor: pointer;
         div:hover{ background: #f5f5fa }
         div{
            height: 94px; width: 114px; padding-left: 20px;
            p{ padding-top: 12px; color: #999999 }
            .p1 { font-weight: 700; }
            .p2 {
               span{ font-size: 26px }
            }
         }
         .active p { color: #444444 }
         .active .p1:before{
            content: ''; position: absolute; width: 114px;
            height: 4px; background: #688ff4; top: 0; left: 0;
         }
      }

   }
   .list{
      .card-wrapper{
         .distr-content{
            display: flex; padding: 20px;
            .left-img{
               margin-right: 40px;
               img { box-shadow: 0 0 9px #aaaaaa }
            }
            .right-form{
               padding: 15px 0; border-bottom: solid 1px #f1f1f1;
               .form-item{
                  overflow: hidden; display: flex;
                  align-items: flex-end;
                  .name{
                     margin-right: 10px; width: 52px; text-align: right; font-size: 13px;
                     line-height: 28px;
                  }
                  .el-select{ margin: 0 }
                  img { display: block }
                  p { font-size: 22px }
               }
            }
         }
      }
   }
   .el-icon-d-arrow-right{
      margin-top: 200%;
      font-size: 1.8rem;
      cursor: pointer;
   }
//}
</style>
